import SportsBaseballOutlinedIcon from "@mui/icons-material/SportsBaseballOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import RotateLeftRoundedIcon from "@mui/icons-material/RotateLeftRounded";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import KeyboardDoubleArrowUpRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowUpRounded";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";

export const navbarContent = [
  {
    caption: "Dashboard",
    link: "/admin/dashboard",
    icon: <HomeOutlinedIcon />,
  },
  {
    caption: "Users",
    link: "/admin/users",
    icon: <PersonOutlineOutlinedIcon />,
  },
  {
    caption: "Players",
    link: "/admin/players",
    icon: <SportsBaseballOutlinedIcon />,
    submenu: [
      {
        caption: "Players",
        link: "/admin/players",
      },
      {
        caption: "Players schedule",
        link: "/admin/players/schedule",
      },
      {
        caption: "Players Grill",
        link: "/admin/players/grill",
      },
    ],
  },
  {
    caption: "Coaches",
    link: "/admin/coaches",
    icon: <PeopleAltOutlinedIcon />,
  },
  {
    caption: "Terms",
    link: "/admin/terms",
    icon: <RotateLeftRoundedIcon />,
  },
  {
    caption: "Courts",
    link: "/admin/courts",
    icon: <TableRowsOutlinedIcon />,
  },
  {
    caption: "Levels",
    link: "/admin/levels",
    icon: <KeyboardDoubleArrowUpRoundedIcon />,
  },
  {
    caption: "Programs",
    link: "/admin/programs",
    icon: <GridViewOutlinedIcon />,
    submenu: [
      {
        caption: "Programs",
        link: "/admin/programs",
      },
      {
        caption: "Private session",
        link: "/admin/programs/private-sessions",
      },
    ],
  },
  {
    caption: "Schedules",
    link: "/admin/schedules",
    icon: <CalendarMonthRoundedIcon />,
    submenu: [
      {
        caption: "Schedule",
        link: "/admin/schedules",
      },
      {
        caption: "Weekly schedule",
        link: "/admin/schedules/weekly-schedule",
      },
    ],
  },
  {
    caption: "Store",
    link: "/admin/store",
    icon: <StorefrontOutlinedIcon />,
    submenu: [
      {
        caption: "Products",
        link: "/admin/store/products",
      },
      {
        caption: "Categories",
        link: "/admin/store/categories",
      },
    ],
  },
  {
    caption: "Purchases",
    link: "/admin/purchases",
    icon: <AttachMoneyRoundedIcon />,
  },
];
