export const player_columns_schedule = [
  {
    name: "DAY NAME",
    width: "15%",
    align: "left",
  },
  {
    name: "COACH",
    width: "15%",
    align: "left",
  },
  {
    name: "COURT",
    width: "10%",
    align: "left",
  },
  {
    name: "LEVEL",
    width: "5%",
    align: "center",
  },
  {
    name: "DURATION",
    width: "5%",
    align: "left",
  },
  {
    name: "PLAYERS",
    width: "50%",
    align: "left",
  },
];
