import { Autocomplete, Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography, Chip, CircularProgress, Button, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import styled from "@emotion/styled";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ContentBox from "../../../components/ui/ContentBox";
import UserType from "../../../components/ui/UserType";
import TableShowUser from "../../../components/ui/TableShowUser";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { member_columns } from "../../../components/data/membersColumns";
import { members_rows } from "../../../components/data/membersRows";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { colors } from "../../../theme/Colors";
import { ErrorMessage, Field, Form, Formik } from "formik";
import BtaDatePicker from "../../../components/ui/BtaDatePicker";
import { modalStyle, modalStyleOverflow } from "../../../theme/ModalStyle";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function UserMembers({ id, updateInfos, setUpdateInfos }) {
  const navigate = useNavigate();
  const [typeProgram, setTypeProgram] = useState("");
  const [level, setLevel] = useState("");
  const [search, setSearch] = useState("");
  const formikRef = useRef();
  const { user } = useAuthContext();
  const [memebers, setMembers] = useState([]);
  const [dateOfBirthUpdate, setDateOfBirthUpdate] = useState(null);

  const [data, setData] = useState({});
  const [openAddPlayer, setOpenAddPlayer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorResponses, setErrorResponses] = useState(false);
  const [openDelete, setOpenDelete] = useState({ open: false, data: null });
  const access_token = user?.access_token;
  const [openEdit, setOpenEdit] = useState({ open: false, data: null });
  const genders = ["MALE", "FEMALE"];
  const [maxDate, setMaxDate] = useState();
  //table style
  const [programs, setPrograms] = useState([]);
  const [levels, setLevels] = useState([]);
  const [changePlayer, setChangePlayer] = useState(false);
  const [initialValuesPlayer, setIntialValues] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
  });

  const validationSchemaPlayer = yup.object({
    first_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    last_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    date_of_birth: yup.date().required("This field is mandatory"),
  });
  const validationSchemaPlayerAdd = yup.object({
    first_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    last_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    date_of_birth: yup.date().required("This field is mandatory"),
    gender: yup.string().required("This field is mandatory"),
  });
  const [initialValues, setInitialValuesUpdate] = useState({
    program: "",
    level: "",
    duration: "",
    frequency: "",
    plannings: [],
  });
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      paddingTop: "8px",
      paddingBottom: "8px",
      letterSpacing: "1px",
      color: `#4B465C`,
      fontSize: 13,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      background: "white",
      fontSize: 14,
      color: `#666D83`,
      fontWeight: "500",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    ":hover": {
      filter: "brightness(0.95)",
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "0px",
  });
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    // Obtenir la date d'aujourd'hui
    var aujourd_hui = new Date();

    // Soustraire deux ans
    aujourd_hui.setFullYear(aujourd_hui.getFullYear() - 2);

    // Obtenir les composants de la date
    var annee = aujourd_hui.getFullYear();
    var mois = aujourd_hui.getMonth() + 1; // Mois commence à 0, donc ajoutez 1
    var jour = aujourd_hui.getDate();

    // Formater la date au format "YYYY-MM-DD"
    var date_il_y_a_deux_ans = annee + "-" + (mois < 10 ? "0" : "") + mois + "-" + (jour < 10 ? "0" : "") + jour;
    console.log(date_il_y_a_deux_ans, "date il ya deux ans");
    setMaxDate(date_il_y_a_deux_ans);
  }, []);
  useEffect(() => {
    Api.get(Urls.GET_PROGRAMS, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: {
        perPage: 30,
      },
    })
      .then((response) => {
        console.log("programs list:", response.data.data);
        setPrograms(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);
  useEffect(() => {
    console.log(typeProgram);

    Api.get(Urls?.GET_PLAYERS_OF_CLIENT + id + "/players", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(typeProgram && { programId: typeProgram?.id }),
        ...(level && { levelId: level?.id }),
        ...(search && { search: search }),
      },
    })
      .then((res) => {
        setMembers(res?.data?.data);
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
        setLoading(false);
      });
  }, [changePlayer, updateInfos, typeProgram, search, level]);
  useEffect(() => {
    Api.get(Urls?.GET_LEVELS, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setLevels(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  }, []);
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log("RESULTAT MATCHED offres DATA", res?.data);
        //console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setMembers(res.data?.data);
        //console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        //console.log(err);
        setMembers([]);
      });
  };
  const handleClickEdit = (data) => {
    setOpenEdit({ open: true, data: data });
    setIntialValues({
      first_name: data?.first_name,
      last_name: data?.last_name,
      date_of_birth: data?.date_of_birth,
    });
  };
  const validationSchema = yup.object({
    program: yup.object().shape({ id: yup?.string().required("This field is required") }),
    duration: yup?.object().shape({ duration: yup?.string().required("This field is required") }),
    level: yup?.object().shape({ id: yup?.string().required("This field is required") }),
    frequency: yup?.object().shape({ id: yup?.string().required("This field is required") }),
  });
  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    if (openEdit?.open) {
      Api.get(Urls?.GET_PLAYER_QUESTIONS, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          date_of_birth: openEdit?.data?.date_of_birth,
        },
      })
        .then((res) => {
          setQuestions(res?.data?.data);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  }, [openEdit]);
  const handleSelectResponse = (index, id) => {
    setQuestions((prevQuestions) => {
      let array = [...prevQuestions];
      let object = array[index];
      array.splice(index, 1);
      object = { ...object, response: id };
      if (index === questions?.length - 1) array.push(object);
      else array.splice(index, 0, object);
      return array;
    });
  };
  const handleUpdatePlayer = (values) => {
    let error = false;
    let responseObject = {};
    for (let i = 0; i < questions.length; i++) {
      responseObject[`answer_question_${i + 1 === 1 ? "one_id" : i + 1 === 2 ? "two_id" : "three_id"}`] = questions[i]?.response;
      if (questions[i]?.response === undefined) {
        setErrorResponses(true);
        console.log(questions[i]?.response);
        error = true;
      }
    }
    let data = { ...values, ...responseObject };
    if (!error) {
      Api.patch(Urls?.UPDATE_PLAYER + openEdit?.data?.id, data, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          toast?.success(res?.data?.message);
          setOpenEdit({ open: false, data: null });
          setChangePlayer(!changePlayer);
          setErrorResponses(false);
        })
        .catch((err) => {
          toast?.error(err?.response?.data?.message);
        });
    }
  };
  const handleAddPlayer = (values) => {
    let error = false;
    let responseObject = {};
    for (let i = 0; i < questions.length; i++) {
      responseObject[`answer_question_${i + 1 === 1 ? "one_id" : i + 1 === 2 ? "two_id" : "three_id"}`] = questions[i]?.response;
      if (questions[i]?.response === undefined) {
        setErrorResponses(true);
        console.log(questions[i]?.response);
        error = true;
      }
    }
    const champs = {
      client_id: id,
      is_client: false,
    };
    let new_values = { ...values, gender: values?.gender.toLowerCase() };
    let data = { ...new_values, ...responseObject, ...champs };
    if (!error) {
      Api.post(Urls?.UPDATE_PLAYER, data, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          toast?.success(res?.data?.message);
          setOpenAddPlayer(false);
          setChangePlayer(!changePlayer);
          setErrorResponses(false);
        })
        .catch((err) => {
          toast?.error(err?.response?.data?.message);
        });
    }
  };
  const handleDeletePlayer = () => {
    Api.delete(Urls?.UPDATE_PLAYER + openDelete?.data?.id, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast?.success(res?.data?.message);
        setOpenDelete({ open: false, data: null });
        setChangePlayer(!changePlayer);
        setUpdateInfos(!updateInfos);
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };
  const handleAddPlayerClick = () => {
    setIntialValues({
      first_name: "",
      last_name: "",
      date_of_birth: "",
      gender: "",
    });
    setQuestions([]);
    setOpenAddPlayer(true);
  };

  const [dateOfBirth, setDateOfBirth] = useState(null);
  useEffect(() => {
    if (dateOfBirth !== null) {
      Api.get(Urls?.GET_PLAYER_QUESTIONS, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          date_of_birth: moment(dateOfBirth).format("yyyy-MM-DD"),
        },
      })
        .then((res) => {
          setQuestions(res?.data?.data);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  }, [dateOfBirth]);
  useEffect(() => {
    if (dateOfBirthUpdate !== null) {
      Api.get(Urls?.GET_PLAYER_QUESTIONS, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          date_of_birth: moment(dateOfBirthUpdate).format("yyyy-MM-DD"),
        },
      })
        .then((res) => {
          setQuestions(res?.data?.data);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  }, [dateOfBirthUpdate]);
  return (
    <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
      <ToastContainer />
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <ContentBox>
          <Box display="flex" flexDirection="column" gap="16px">
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
              <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                List of associated members
              </Typography>
              <Box>
                <Button onClick={handleAddPlayerClick} variant="primary_active">
                  Add player
                </Button>
              </Box>
            </Box>
            <Box marginTop="8px" display="flex" flexWrap="wrap" gap="16px" flexDirection="row" justifyContent="flex-end">
              <TextField value={search} onChange={handleSearchChange} type="input" placeholder="Search member" />
              <Autocomplete
                sx={{ minWidth: "300px" }}
                options={programs}
                getOptionLabel={(e) => e?.name}
                onChange={(event, value) => {
                  if (value !== null) {
                    //   console.log("selecting facture state value", value);
                    //   console.log("selecting facture state value", value.id);
                    setTypeProgram(value);
                    //   console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                  } else {
                    setTypeProgram(null);
                  }
                }}
                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select a program" />}
              />
              <Autocomplete
                sx={{ minWidth: "300px" }}
                options={levels}
                getOptionLabel={(e) => e?.name}
                onChange={(event, value) => {
                  if (value !== null) {
                    //   console.log("selecting facture state value", value);
                    //   console.log("selecting facture state value", value.id);
                    setLevel(value);
                    //   console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                  } else {
                    setLevel(null);
                  }
                }}
                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select a level" />}
              />
            </Box>
            <TableContainer sx={{ borderRadius: "0px", border: `1px solid #DBDADE` }}>
              <StyledTable aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {member_columns.map((col, index) => (
                      <StyledTableCell width={col.width} align={col.align}>
                        {col.name}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {memebers?.map((player) => (
                    <StyledTableRow key={player?.id}>
                      <StyledTableCell onClick={() => navigate(`/admin/players/${player.id}`)} align="left">
                        <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                          #{player?.id}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell onClick={() => navigate(`/admin/players/${player.id}`)} align="left">
                        <Box>
                          <TableShowUser url_photo={player?.url_avatar} email={player?.email} full_name={player?.first_name + " " + player?.last_name} />
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell onClick={() => navigate(`/admin/players/${player.id}`)} align="left">
                        {player.gender ? player.gender : "-"}
                      </StyledTableCell>
                      <StyledTableCell onClick={() => navigate(`/admin/players/${player.id}`)} align="left">
                        {player.date_of_birth ? moment(player.date_of_birth).format("DD/MM/yyyy") : "-"}
                      </StyledTableCell>
                      <StyledTableCell onClick={() => navigate(`/admin/players/${player.id}`)} align="center">
                        <Chip variant="primary" label={player.level_name ? player?.level_name : "-"} />
                      </StyledTableCell>
                      <StyledTableCell onClick={() => navigate(`/admin/players/${player.id}`)} align="left">
                        {player?.program && <Chip variant="primary" label={player.program?.name} />}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        <Box justifyContent="left" display="flex" gap="2px">
                          <ButtonSquare onClick={() => handleClickEdit(player)} action="edit_outlined" />
                          <ButtonSquare onClick={() => setOpenDelete({ open: true, data: player })} action="delete_red" />
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
            {memebers?.length === 0 && !loading && <Typography textAlign="center">No Members</Typography>}
            {memebers?.length > 0 && (
              <Box justifyContent="flex-end" display="flex" gap="4px">
                {data?.links?.prev && (
                  <ButtonSquare
                    onClick={() => {
                      handlePagination(data?.links?.prev);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action="Previous"
                  />
                )}
                <Box display="flex" gap="4px" justifyContent="center">
                  {data?.meta?.links?.map((link) => (
                    <ButtonSquare
                      onClick={() => {
                        handlePagination(link.url);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      action={link?.active ? "number-active" : "number"}
                      number={link.label}
                    />
                  ))}
                </Box>
                {data?.links?.next && (
                  <ButtonSquare
                    onClick={() => {
                      handlePagination(data?.links?.next);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action="Next"
                  />
                )}
              </Box>
            )}
          </Box>
        </ContentBox>
      )}
      <Modal open={openEdit?.open} onClose={() => setOpenEdit({ open: false, data: null })}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={() => setOpenEdit({ open: false, data: null })} />
          </Box>
          <Box>
            <Formik onSubmit={(values) => handleUpdatePlayer(values)} validationSchema={validationSchemaPlayer} initialValues={initialValuesPlayer} enableReinitialize={true}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box marginBottom="26px">
                    <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                      Update player
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          First name
                        </Typography>
                        <Field style={{ width: "100%" }} name="first_name" type="input" id="first_name" as={TextField} autoComplete="off" placeholder="John" />
                        <ErrorMessage component={FormikErrorText} name="first_name" />
                      </Box>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Last name
                        </Typography>
                        <Field style={{ width: "100%" }} name="last_name" type="input" id="last_name" as={TextField} autoComplete="off" placeholder="Doe" />
                        <ErrorMessage component={FormikErrorText} name="last_name" />
                      </Box>
                    </Box>

                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Date of birth{" "}
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          value={values?.date_of_birth}
                          maxDate={maxDate}
                          onChange={(e) => {
                            setFieldValue("date_of_birth", moment(e).format("yyyy-MM-DD"));
                            setDateOfBirthUpdate(e);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>{" "}
                      <ErrorMessage component={FormikErrorText} name="date_of_birth" />
                    </Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Questions :
                      </Typography>
                      <Box display="flex" flexDirection="column" gap="10px">
                        {questions?.map((question, index) => (
                          <Box key={question?.id} display="flex" flexDirection="column" gap="5px">
                            <Typography marginLeft="20px" fontSize="15px" fontWeight="500" color="#4B465C">
                              - {question?.value}
                            </Typography>
                            {question?.answers?.map((ans) => (
                              <Box onClick={() => handleSelectResponse(index, ans?.id)} sx={question?.response !== ans?.id ? { borderRadius: "15px", border: "1px solid lightgray", textAlign: "center", cursor: "pointer", padding: "10px 10px" } : { borderRadius: "15px", border: "1px solid lightgray", textAlign: "center", cursor: "pointer", padding: "10px 10px", backgroundColor: `${colors.main}` }} key={ans?.id}>
                                {ans.value}
                              </Box>
                            ))}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Box textAlign="center">{errorResponses && <FormikErrorText>Please answer all the questions</FormikErrorText>}</Box>
                    <Box marginTop="10px" justifyContent="center" display="flex" gap="16px">
                      <Button type="submit" variant="primary_active">
                        update
                      </Button>
                      <Button onClick={() => setOpenEdit({ open: false, data: null })} variant="primary_inactive">
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
      <Modal open={openDelete?.open} onClose={() => setOpenDelete({ open: false, data: null })}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={() => setOpenDelete({ open: false, data: null })} />
          </Box>
          <Box marginBottom="26px">
            <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
              Delete player
            </Typography>
          </Box>
          <Box>
            <Typography textAlign="center" fontSize="22px" lineHeight="32px" fontWeight="500" color="#4B465C">
              Are you sure you want to delete this player (id : {openDelete?.data?.id})?
            </Typography>
          </Box>
          <Box marginTop="16px" justifyContent="center" display="flex" gap="16px">
            <Button onClick={handleDeletePlayer} variant="primary_active">
              Delete
            </Button>
            <Button onClick={() => setOpenDelete({ open: false, data: null })} variant="primary_inactive">
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openAddPlayer} onClose={() => setOpenAddPlayer(false)}>
        <Box sx={modalStyleOverflow}>
          <Box position="absolute" top="2px" right="2px">
            <ButtonSquare action="close" onClick={() => setOpenAddPlayer(false)} />
          </Box>
          <Box>
            <Formik innerRef={formikRef} onSubmit={(values) => handleAddPlayer(values)} validationSchema={validationSchemaPlayerAdd} initialValues={initialValuesPlayer} enableReinitialize={true}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box marginBottom="26px">
                    <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                      Add player
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          First name
                        </Typography>
                        <Field style={{ width: "100%" }} name="first_name" type="input" id="first_name" as={TextField} autoComplete="off" placeholder="John" />
                        <ErrorMessage component={FormikErrorText} name="first_name" />
                      </Box>
                      <Box flex={1}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Last name
                        </Typography>
                        <Field style={{ width: "100%" }} name="last_name" type="input" id="last_name" as={TextField} autoComplete="off" placeholder="Doe" />
                        <ErrorMessage component={FormikErrorText} name="last_name" />
                      </Box>
                    </Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Gender{" "}
                      </Typography>
                      <Autocomplete
                        sx={{ minWidth: "100%" }}
                        options={genders}
                        getOptionLabel={(e) => e}
                        onChange={(event, value) => {
                          if (value !== null) {
                            setFieldValue("gender", value);
                          } else {
                            setFieldValue("gender", null);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select a gender" />}
                      />
                      <ErrorMessage component={FormikErrorText} name="gender" />
                    </Box>
                    <Box>
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Date of birth{" "}
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          value={values?.date_of_birth}
                          maxDate={maxDate}
                          onChange={(e) => {
                            setFieldValue("date_of_birth", moment(e).format("yyyy-MM-DD"));
                            setDateOfBirth(e);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>{" "}
                      <ErrorMessage component={FormikErrorText} name="date_of_birth" />
                    </Box>
                    {values?.date_of_birth && (
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Questions :
                        </Typography>
                        <Box display="flex" flexDirection="column" gap="10px">
                          {questions?.map((question, index) => (
                            <Box key={question?.id} display="flex" flexDirection="column" gap="5px">
                              <Typography marginLeft="20px" fontSize="15px" fontWeight="500" color="#4B465C">
                                - {question?.value}
                              </Typography>
                              {question?.answers?.map((ans) => (
                                <Box onClick={() => handleSelectResponse(index, ans?.id)} sx={question?.response !== ans?.id ? { borderRadius: "15px", border: "1px solid lightgray", textAlign: "center", cursor: "pointer", padding: "10px 10px" } : { borderRadius: "15px", border: "1px solid lightgray", textAlign: "center", cursor: "pointer", padding: "10px 10px", backgroundColor: `${colors.main}` }} key={ans?.id}>
                                  {ans.value}
                                </Box>
                              ))}
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    )}
                    <Box textAlign="center">{errorResponses && <FormikErrorText>Please answer all the questions</FormikErrorText>}</Box>
                    <Box marginTop="10px" justifyContent="center" display="flex" gap="16px">
                      <Button type="submit" variant="primary_active">
                        Add
                      </Button>
                      <Button onClick={() => setOpenAddPlayer(false)} variant="primary_inactive">
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default UserMembers;
